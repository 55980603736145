
<script setup>
    import { ref, onMounted, onBeforeUnmount } from "vue";
    import { hapticsImpact } from "mobile.utils.ts";

    const props = defineProps({
        showSpinner: Boolean,
        pullRefresh: [Boolean, Function],
    });

    const div = ref(null);
    const pullSpinner = ref(null);


    const state = {};

    function onTouchStart(e) {
        state.x = 0;
        state.y = 0;

        state.vel_x = 0;
        state.vel_y = 0;
        
        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;

        state.cancel = false;
        state.cancelPull = false;
        state.prevent = false;

        state.firstFrame = true;
    }

    function isScrolledToTop(el) {
        return el.scrollTop === 0;
    }

    function isScrolledToBottom(el) {
        return el.scrollHeight - el.scrollTop - el.clientHeight === 0;
    }

    function getCurrentScrollElement(el, dif_x, dif_y) {
        if (!el || el === div) {
            return;
        }
        // vertical scroll
        if (el.scrollHeight > el.clientHeight) {
            if (dif_y > 0 && !isScrolledToTop(el)) {
                return el;
            }
            if (dif_y < 0 && !isScrolledToBottom(el)) {
                return el;
            }
        }
        return getCurrentScrollElement(el.parentElement, dif_x, dif_y);
    }

    async function onTouchMove(e) {
        // precalculate
        const dif_x = e.touches[0].clientX - state.prev_x;
        const dif_y = e.touches[0].clientY - state.prev_y;
        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;

        const scroll = getCurrentScrollElement(e.target, dif_x, dif_y);

        console.log("scroll", scroll);

        if (state.firstFrame) {
            if (!scroll) {
                state.prevent = true;
            } else {
                state.cancel = true;
            }
        }

        if (state.prevent) {
            e.preventDefault();
        } else {
            return;
        }

        if (state.cancel) {
            return;
        }

        state.x += dif_x;
        state.y += dif_y;

        let offset_y = state.y ** 0.8;

        let offset_t = offset_y / 75;

        let t
        t = (offset_t - 0.15)/(1 - 0.15);
        t = Math.min(Math.max(t, 0), 1);
        t = t**(1/3);

        pullSpinner.value.style.opacity = `${t*100}%`;
        pullSpinner.value.style.transform = `translate(-50%, -100%) rotate(${t*180}deg)`;



        div.value.style.transition = "all 0ms";
        div.value.style.transform = `translate(0%, ${offset_y}px)`;

        state.firstFrame = false;
    }

    function onTouchEnd(e) {
        div.value.style.transition = "all 250ms";
        div.value.style.transform = `translate(0%, 0%)`;

        if (state.cancel) {
            return;
        }
    }

    onMounted(() => {
        console.log("Container.vue: onMounted");
        if (props.pullRefresh) {
            console.log("Container.vue: pullRefresh enabled");
            if (div.value) {
                div.value.addEventListener("touchstart", onTouchStart);
                div.value.addEventListener("touchmove", onTouchMove);
                div.value.addEventListener("touchend", onTouchEnd);
            }
        }
    });

    onBeforeUnmount(() => {
        if (div.value) {
            div.value.removeEventListener("touchstart", onTouchStart);
            div.value.removeEventListener("touchmove", onTouchMove);
            div.value.removeEventListener("touchend", onTouchEnd);
        }
    });
</script>

<template>
    <div class="afm-container" ref="div" style="overflow: visible;">
        <slot />
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none;" v-if="props.showSpinner">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <template v-if="pullRefresh">
            <div ref="pullSpinner" style="position: absolute; top: 0; left: 50%; transform: translate(-50%, -100%);">
                <i class="fas fa-spinner" style="font-size: 2rem;" />
            </div>
        </template>
    </div>
</template>

<style>
    .afm-container > div {
        flex-shrink: 0;
    }
</style>

<style scoped>
    .afm-container {
        position: absolute;
        inset: 0;

        border-radius: inherit;

        /*
        overflow-y: auto;
        overflow-x: hidden;
        */
        overflow: hidden;

        display: flex;
        flex-direction: column;
    }
</style>
